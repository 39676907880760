<template>
    <b-card>
  
     
  
  
               <b-row>

         
  
               <b-col
                      cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                    v-if="loading"
                    align="center"
                  >
  
                  <b-row>
  
                    <b-col cols="4" sm="4" md="4" lg="4" xl="4">
  
  
  
                    </b-col>
  
                      <b-col cols="4" sm="4" md="4" lg="4" xl="4" align="center">
  
                    <b-skeleton type="avatar" width="60px" height="60px"></b-skeleton>
  
  
                    </b-col>
  
                    <b-col cols="4" sm="4" md="4" lg="4" xl="4">
  
  
  
                    </b-col>
  
                    <b-col cols="2" sm="2" md="2" lg="2" xl="2"></b-col>
  
                    <b-col cols="8" sm="8" md="8" lg="8" xl="8" style="margin-top: 10px;">
                      <b-skeleton width="100%"  height="10px"></b-skeleton>
  
                      </b-col>
  
                      <b-col cols="2" sm="2" md="2" lg="2" xl="2"></b-col>
  
                      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
  
  
                        <b-skeleton width="100%"  height="40px"></b-skeleton>
  
  
                      </b-col>
  
                      <b-col cols="12" sm="6" md="6" lg="6" xl="6" style="margin-top: 15px;">
  
                        <b-skeleton width="100%"  height="10px"></b-skeleton>
  
                        <b-skeleton width="100%"  height="30px"></b-skeleton>
  
                        </b-col>
  
                        <b-col cols="12" sm="6" md="6" lg="6" xl="6" style="margin-top: 15px;">
  
                        <b-skeleton width="100%"  height="20px"></b-skeleton>
  
                        <table width="100%">
                                    <tr>
                                      <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    </tr>
                                  </table>
  
                        </b-col>
  
                        <b-col
                                cols="4"
                                    sm="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                  > </b-col>
  
  
                                <b-col
                                cols="4"
                                    sm="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                  >
  
                                  <b-skeleton width="100%"  height="40px"></b-skeleton>
                                
                                </b-col>
  
  
                                <b-col
                                cols="4"
                                    sm="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                  > </b-col>
  
  
                    
  
  
                  </b-row>
  
                  </b-col>
  
                   <b-col
                    v-else
                        align="center"
                        style="padding: 0;"
                      >
  
                        <b-col
                          v-if="hasWhatsapp"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                        >
  
                        
                        <b-modal
                          ref="modalEliminar"
                          centered
                          hide-footer
                          title="Verificación de celular"
                          size="sm"
                        >
                          <div class="d-block text-center">
  
                            <b-form
                              autocomplete="off"
                              @submit.prevent="onSubmitDelete"
                            >
  
                              <b-row>
  
                                <b-col
                                  sm="12"
                                  md="12"
                                  lg="12"
                                  xl="12"
                                  style="margin-bottom: 10px;"
                                >
  
                                  <p
                                    class="textLabel"
                                    style="text-align: center;"
                                  >
                                    Ingrese el código de verificación enviado a su celular
                                  </p>
  
                                  <PincodeInput
                                    v-model="pinCode"
                                    placeholder="-"
                                    :length="6"
                                    :autofocus="false"
                                    :secure="false"
                                    :readonly="isActiveEliminacion"
                                    required
                                  />
  
                                </b-col>
  
                                <b-col
                                  sm="12"
                                  md="12"
                                  lg="12"
                                  xl="12"
                                >
  
                                  <p
                                    class="textLabel"
                                    style="text-align: center;"
                                  >
                                    Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>
  
                                  <PincodeInput
                                    v-model="pinEliminacion"
                                    placeholder="-"
                                    :length="6"
                                    :autofocus="false"
                                    :secure="true"
                                    :readonly="isActiveEliminacion"
                                    required
                                  />
  
                                </b-col>
  
                                <b-col
                                  sm="12"
                                  md="12"
                                  lg="12"
                                  xl="12"
                                  style="margin-top: 15px;"
                                >
  
                                  <b-button
                                    block
                                    id="btn_eliminar_celular"
                                    ref="btn_eliminar_celular"
                                    type="submit"
                                    variant="primary"
                                    :disabled="isActiveEliminacion"
                                  >
  
                                    Confirmar eliminación
  
                                  </b-button>
  
                                </b-col>
  
                              </b-row>
  
                            </b-form>
  
                          </div>
  
                        </b-modal>
  
                          <img
  
                            alt="Todo ok"
                            src="/img/ok.svg"
                          >
  
                          <p style="text-align: center; font-size: 14px;">
                            Ya tienes configurado notificaciones por whatsapp
                          </p>
                          <b-alert
                            variant="primary"
                            show
                          >
                            <div class="alert-body">
                              <span> El número {{ phoneWhatsapp }} se encuentra actualmente habilitado para recibir notificaciones de pagos por whatsapp.</span>
                            </div>
                          </b-alert>
  
                          <b-row>
                            <b-col
                                sm="12"
                                md="12"
                                lg="3"
                                xl="3"
                                align="center"
                              />
  
                              <b-col
                                sm="12"
                                md="12"
                                lg="6"
                                xl="6"
                                align="center"
                              >
  
                                <b-button
                                  style="margin-top:10px"
                                  @click="deletePhone()"
                                  variant="outline-danger"
                                  block
                                >
                                <feather-icon
                                icon="TrashIcon"
                                size="14"
                                style="margin-right: 5px;"
                                />
                                  Eliminar número celular
  
                                </b-button>
  
                              </b-col>
  
                              <b-col
                                sm="12"
                                md="12"
                                lg="3"
                                xl="3"
                                align="center"
                              />
                          </b-row>
  
                          <hr>
  
                          <p class="hasNotificacion" style="margin-top:10px">
                            ¿Deseas modificar tu número de whatsapp?
                          </p>
  
                 
  
                       
  
  
                        </b-col>
  
                        <b-col
                          v-else
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                          style="padding: 0;"
                        >
  
                          <img
                          style="margin-bottom:15px;"
  
                            alt="No tiene whatsapp"
                            src="/img/no_phone.svg"
                          >

                          <b-alert
                            variant="primary"
                            show
                          >
                            <div class="alert-body">
                              <p style="text-align: center;  font-size:14px">
                            No tienes configurado un número celular con whatsapp para tu negocio
                          </p>
  
                          
                            </div>
                          </b-alert>
  
                    
                   
  
                        
  
                        </b-col>
  
                        <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                        >
  
                        <b-form
                            autocomplete="off"
                            @submit.prevent="onSubmit2"
                          >
  
                            <b-row>
  
                              <b-col
                                sm="12"
                                md="12"
                                lg="6"
                                xl="6"
                                align="center"
                              >
  
                                <p
                                  class="textLabel"
                                  style="text-align: center;margin-top: 10px; margin-bottom: 10px;"
                                >
                                  Ingrese un número celular (*):
                                </p>
  
                                <MazPhoneNumberInput
                                  v-model="phoneNumber"
                                  type="tel"
                                  style="margin-bottom: 10px;"
                                  default-country-code="EC"
                                  no-flags
                                  no-example
                                  show-code-on-list
                                  size="md"
                                  :translations="{
                                    countrySelectorLabel: 'Codigo',
                                    countrySelectorError: 'Seleccione un pais',
                                    phoneNumberLabel: 'Celular:',
                                  }"
                                  @update="results = $event"
                                />
  
                              </b-col>
  
                              <b-col
                                sm="12"
                                md="12"
                                lg="6"
                                xl="6"
                                align="center"
                              >
  
                                <p
                                  class="textLabel"
                                  style="text-align: center;margin-top: 10px; margin-bottom: 10px;"
                                >
                                  Ingrese su código PIN (*): <feather-icon
                                    icon="HelpCircleIcon"
                                    size="16"
                                      v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                    title="Código PIN de seguridad"
                                    style="margin-right: 5px;cursor:pointer;"
                                    /> </p>
  
                                <PincodeInput
                                  v-model="pinPhone"
                                  placeholder="-"
                                  :length="6"
                                  :secure="true"
                                  required
                                  :autofocus="false"
                                />
  
                              </b-col>
  
                              <b-col
                                sm="12"
                                md="12"
                                lg="3"
                                xl="3"
                                align="center"
                              />
  
                              <b-col
                                sm="12"
                                md="12"
                                lg="6"
                                xl="6"
                                align="center"
                              >
  
                                <b-button
                                  id="btn_number"
                                  ref="btn_number"
                                  style="margin-top:10px"
                                  type="submit"
                                  variant="primary"
                                  block
                                  :disabled="isActivePhone"
                                >
  
                                Registrar número celular
  
                                </b-button>
  
                              </b-col>
  
                              <b-col
                                sm="12"
                                md="12"
                                lg="3"
                                xl="3"
                                align="center"
                              />
  
                            </b-row>
  
                          </b-form>
  
             
                        </b-col>
  
                              </b-col>
  
  
                           
  
                        </b-row>
  
  
  
      <b-modal
        ref="modalPhone"
        centered
        hide-footer
        size="sm"
        title="Código de verificación de celular"
      >
        <div class="d-block text-center">
  
          <p class="p_login">
            Ingrese el código de verificación enviado a su celular
          </p>
  
          <PincodeInput
            v-model="sendCode"
            placeholder="-"
            :length="6"
            :autofocus="false"
            :secure="false"
          />
  
        </div>
  
      </b-modal>
  
  
  
    </b-card>
  </template>
  
  <script>
  import {
    BButton, BForm, BAlert,BFormInput, VBPopover, BFormGroup, BSkeleton, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
  } from 'bootstrap-vue'

  import PincodeInput from 'vue-pincode-input'

  
  export default {
    components: {
      PincodeInput,
      BButton,
      BForm,
      BAlert,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BCard,
      BInputGroup,
      BInputGroupAppend,
      BSkeleton,
    },
    directives: {
      'b-popover': VBPopover,
    },
    props: ['userId','tokenAuth', 'idLocal'],
    data() {
      return {
        hasWhatsapp: false,
        loading: true,
        phoneWhatsapp: '',
        isActivePhone: false,
        results: null,
        phoneNumber: null,
        sendCode: '',
        pinPhone: '',
        pinCode:"",
        pinEliminacion:"",
        isActiveEliminacion:false,
  
      }
    },
    computed: {
  
    },
    watch: {
      results(newValue) {
        if (newValue.isValid) {
          this.isActivePhone = false
          this.phoneNumber = newValue.e164
        } else {
          this.isActivePhone = true
          this.phoneNumber = ''
          this.sendCode = ''
        }
      },
      sendCode(newValue) {
        if (newValue.length == 6) {
          this.verifyCode(newValue)
        }
      }
  
    },
    mounted() {
  
  
        this.loadWhatsapp();

  
    },
    methods: {

        loadWhatsapp(){

            
      const userId_json = { userId: this.userId, idLocal: this.idLocal }
      const user_string = JSON.stringify(userId_json)
  
      const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
  
      this.$https.post('/locals/checkHasWhatsapp/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
       
        if (response.data.code == 200) {
         
          this.loading = false
          this.phoneWhatsapp = response.data.phoneWhatsapp;
          this.hasWhatsapp = response.data.hasWhatsapp;

        } else {
      
  
          if (response.data.code == 401) {

            this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
            
            localStorage.removeItem('userData')

            
  
            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else {

            if( response.data.code === 404){
              this.$eventBus.$emit('activeHome');
              this.$eventBus.$emit('reiniciarAllNegocio');
            }else{
              this.loadWhatsapp();
            }
      
          }
        }
      }).catch(error => {
  
        this.loadWhatsapp();
        
      })

        },
  
      
      onSubmitDelete(event) {
        event.preventDefault()
  
        const userId_json = { userId: this.userId, pin: this.pinEliminacion, code: this.pinCode, id: this.idLocal  }
        const user_string = JSON.stringify(userId_json)
  
        this.isActiveEliminacion = true
  
        const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
  
        document.getElementById('btn_eliminar_celular').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Eliminando, espere'
  
        this.$https.post('/locals/deleteWhatsapp/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
          this.isActiveEliminacion = false
  
          document.getElementById('btn_eliminar_celular').innerHTML = 'Confirmar eliminacion'
  
          if (response.data.code == 200) {
  
            this.$toast.success(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
  
            this.pinCode = ''
            this.pinEliminacion = ''
  
            this.$refs.modalEliminar.hide();

            this.$eventBus.$emit('reiniciarPerfilCompletado2', false)
            this.$eventBus.$emit('reiniciarPerfilCompletado')
  
            this.refreshNotificaciones();
  
            
          } else {
            this.$toast.error(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
  
            if (response.data.code == 401) {
              
              localStorage.removeItem('userData')
  
  
  
              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            } else if (response.data.code == 503) {
              this.pin = ''
            } else if (response.data.code == 504) {
              this.$refs.modalEliminar.hide();
  
            }
          }
        }).catch(error => {
          this.$toast.error("Ocurrió un error inesperado", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        })
      },
  
  
      deletePhone(){
  
  
        this.$swal({
          title: '¿Seguro que deseas eliminar este número?',
          text: `Dejarás de recibir notificaciones de tu negocio por whatsapp`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, eliminar',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-secondary ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm: () => {
 
  
            return this.$https.post('/locals/deleteWhatsapp2/', { tokenAuth: this.tokenAuth, userId: this.userId, id: this.idLocal }).then(response => response.data).catch(error => {
              this.$swal.showValidationMessage(
                error,
              )
            })
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        }).then(result => {
  
          this.$swal.close()
  
          if(result.isConfirmed){
  
             if (result.value.code == 200) {

              this.sendCode = ''
            this.pinPhone = ''
            this.phoneNumber = ''


            this.$eventBus.$emit('reiniciarPerfilCompletado2', false)
            this.$eventBus.$emit('reiniciarPerfilCompletado')
  
  
            this.refreshNotificaciones();
          
  
               this.$toast.success(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            });
      
                    } else {
                      this.$toast.error(result.value.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                      })
  
                      if (result.data.code == 401) {
                        
                        localStorage.removeItem('userData')
  
                        // Redirect to login page
                        this.$router.push({ name: 'auth-login' })
                      }
                    }
  
          }
          
  
  
  
     
        })
  
  
      },
  
      verifyCode(codigo) {
        this.$swal({
  
          html:'<img style="margin-left:15px;width:20px; " src="/img/loading.svg" alt="Loading" /> Verificando código, espere',
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          position: 'center',
        })
  
        const userId_json = {
          userId: this.userId, pin: this.pinPhone, codigo: codigo, celular: this.phoneNumber, idLocal: this.idLocal
        }
        const user_string = JSON.stringify(userId_json)
  
        const userEncripted = this.$encryptBackoffice.encrypt(user_string)
  
        this.$https.post('/locals/confirmWhatsapp/', { tokenAuth: this.tokenAuth, data: userEncripted }).then(response => {
          this.$swal.close()
  
          if (response.data.code == 200) {
  
           
  
  
            this.sendCode = ''
            this.pinPhone = ''
            this.phoneNumber = ''
            this.$refs.modalPhone.hide();

            this.$eventBus.$emit('reiniciarPerfilCompletado2', false)
            this.$eventBus.$emit('reiniciarPerfilCompletado')
  
  
            this.refreshNotificaciones();
          
  
               this.$toast.success(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            });
  
 
  
          } else {
            this.sendCode = ''
  
            this.$toast.error(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          }
        }).catch(error => {
          this.sendCode = ''
          this.$toast.error(error, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        })
      },
      refreshNotificaciones() {
        this.$eventBus.$emit('reiniciarWhatsappNegocio')
      },
  
      onSubmit2(event) {
        event.preventDefault()
  
        const userId_json = { userId: this.userId, pin: this.pinPhone, celular: this.phoneNumber, idLocal: this.idLocal }
        const user_string = JSON.stringify(userId_json)
  
      
  
        this.isActivePhone = true
  
        const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
  
        document.getElementById('btn_number').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Actualizando, espere'
  
        this.$https.post('/locals/saveWhatsapp/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
          this.isActivePhone = false
  
          document.getElementById('btn_number').innerHTML = 'Registrar número celular'
  
          if (response.data.code == 200) {

          
  
            this.sendCode = ''
            this.pinPhone = ''
            this.phoneNumber = ''


            this.$eventBus.$emit('reiniciarPerfilCompletado2', false)
            this.$eventBus.$emit('reiniciarPerfilCompletado')
  
  
            this.refreshNotificaciones();
          
  
               this.$toast.success(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            });
  

          } else {
            this.$toast.error(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
  
            if (response.data.code == 401) {
              
              localStorage.removeItem('userData')
  
  
  
              
              
  
             
  
              
              
  
              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            } else if (response.data.code == 503) {
              this.pinPhone = ''
            } else if (response.data.code == 504) {
              this.phoneNumber = ''
            }
          }
        }).catch(error => {
          this.$toast.error("Ocurrió un error inesperado", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        })
      },

  
    },
  }
  </script>
  
  <style lang="scss">
  

  
  .hasNotificacion{
        text-align: center;
      font-weight: bold;
      font-size: 15px;
  }
  
  
  
  </style>
  